import React from 'react';
import type { FC } from 'react';
import { Helmet } from 'react-helmet';
import { Container, Typography } from '@material-ui/core';
import { Layout } from '../components/layouts/Layout';
import { Link } from 'gatsby';

const Page: FC = () => (
  <Layout>
    <Helmet>
      <meta charSet="utf-8" />
      <title>QSL Print.com - error</title>
      <meta name="description" content="自宅にプリンタが無くてもQSLカードの宛名面を印刷できます。"/>
      <meta name="keywords" content="アマチュア無線,QSLカード,印刷"/>
    </Helmet>
    <Typography variant="h1">404 Not Found</Typography>

    <Container>
      <Typography>
        お探しのページは見つかりませんでした。
      </Typography>
      <Typography><Link to="/">トップページ</Link></Typography>
    </Container>


  </Layout>
);
export default Page;
